<template>
    <div>
        <b-row class="mb-3" >
            <b-col class="text-right">
                <b-button size="sm" @click="createModal" class="mr-1" variant="primary"><i class="fas fa-plus-circle" v-if="$auth.check('producers.create')"></i> Neuer Kontakt</b-button>
            </b-col>
        </b-row>

        <b-row v-if="contacts.length >= 1">
            <b-col>
                <vue-good-table 
                    :columns="columns"
                    :rows="contacts"
                    :search-options="{ 
                        enabled: true
                    }"
                    :pagination-options="{
                        enabled: true,
                        mode: 'pages',
                        perPage: 20,
                        perPageDropdownEnabled: true,
                        nextLabel: 'Nächste',
                        prevLabel: 'Vorherige',
                        rowsPerPageLabel: 'Zeilen pro Seite',
                        ofLabel: 'von',
                        pageLabel: 'Seite',
                        allLabel: 'Alle',
                    }"
                    styleClass="vgt-table condensed striped">

                    <template slot="table-row" slot-scope="props">
                        <span v-if="props.column.field == 'action'">
                            <b-button size="sm" @click="editModal(props.row)" class="mr-1" variant="warning" v-if="$auth.check('producers.edit')"><i class="fas fa-edit" ></i></b-button>
                            <b-button size="sm" @click="deleteContact(props.row.id)" variant="danger" v-if="$auth.check('producers.destroy')"><i class="fas fa-trash" ></i></b-button>
                        </span>    
                    </template>
                    
                </vue-good-table>
            </b-col>
        </b-row>
    
        <b-row v-if="contacts.length == 0">
            <b-col>
                <h4>Dieser Händler hat keine Kontakte</h4>
            </b-col>
        </b-row>


        <b-modal id="contactModal" v-bind:title="modalTitle" ok-only ok-variant="danger" ok-title="Speichern" size="xl" @ok="handleOk($event, contactForm.id)">
            <form ref="contactForm" @submit.stop.prevent="handleSubmit">
                <div class="row">
                    <div class="col-md-12">

                        <div class="form-group">
                            <label for="name" class="control-label">Name*</label>
                            <input v-model="contactForm.name" type="text" class="form-control form-cotrol-sm" :class="{'is-invalid': contactForm.errors.has('name')}">
                            <has-error :form="contactForm" field="name"></has-error>
                        </div>

                        <div class="form-group">
                            <label for="department" class="control-label">Abteilung*</label>
                            <input v-model="contactForm.department" type="text" class="form-control form-cotrol-sm" :class="{'is-invalid': contactForm.errors.has('department')}">
                            <has-error :form="contactForm" field="department"></has-error>
                        </div>

                        <div class="form-group">
                            <label for="email" class="control-label">E-Mail</label>
                            <input v-model="contactForm.email" type="email" class="form-control form-cotrol-sm" :class="{'is-invalid': contactForm.errors.has('email')}">
                            <has-error :form="contactForm" field="email"></has-error>
                        </div>

                        <div class="form-group">
                            <label for="phone" class="control-label">Telefon</label>
                            <input v-model="contactForm.phone" type="text" class="form-control form-cotrol-sm" :class="{'is-invalid': contactForm.errors.has('phone')}">
                            <has-error :form="contactForm" field="phone"></has-error>
                        </div>

                        
                    </div>
                </div>
            </form>
        </b-modal>
    </div>
</template>

<script>
export default {

    name: "Contacts",

    props: [
        'contacts',
    ],

    data(){
        return {
            modalTitle: "Neuen Kontakt erstellen",
            contactForm: new window.Form({
                id: "",
                name: "",
                department: '',
                phone: '',
                email: '',
            }),
            columns: [
                {field: "name", label: "Name"},
                {field: "department", label: "Abteilung"},
                {field: "email", label: "E-Mail"},
                {field: "phone", label: "Telefon"},
                {field: "action", label: "Actions", tdClass: 'text-center', width: '7%'},
            ],
        }
    },

    methods:{
        handleOk(bvModalEvt, id) {
            bvModalEvt.preventDefault()
            this.handleSubmit(id)
        },

        handleSubmit(id) {
            if(this.editModeContact == true)
            {
                //Edit User
                this.editContact(id);
            }
            else
            {
                //Create User
                this.createContact();
            }
        },

        createModal(){
            this.contactForm.clear();
            this.contactForm.reset();
            this.editModeContact = false;
            this.$bvModal.show("contactModal");
        },

        editModal(Contact){
            this.editModeContact = true;
            this.modalTitle = "Kontakt bearbeiten";
            this.contactForm.reset();
            this.contactForm.fill(Contact);
            this.$bvModal.show("contactModal");
        },

        createContact() {
            this.$Progress.start();
            this.contactForm
                .post("/producers/" + this.$route.params.id + '/contacts')
                .then(() => {
                    this.$parent.loadProducer();
                    this.$bvModal.hide("contactModal");
                    this.$swal({
                        icon: "success",
                        title: "Kontakt wurde hinzugefügt",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.$Progress.finish();
                    
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufennnnn",
                    });
                    this.$Progress.fail();
                });
        },

        editContact(id) {
            this.$Progress.start();
            this.contactForm
                .put("/producers/" + this.$route.params.id + '/contacts/' + id)
                .then(() => {
                    this.$parent.loadProducer();
                    this.$bvModal.hide("contactModal");
                    this.$swal({
                        icon: "success",
                        title: "Der Kontakt wurde editiert",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.$Progress.finish();
                    
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufennnnn",
                    });
                    this.$Progress.fail();
                });
        },

        deleteContact(id){
            this.$swal({
                title: "Möchtest du den Kontakt wirklich löschen?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: `Ja, löschen!!!`,
                denyButtonText: `Abbrechen`,
            })
            .then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    //Send request to the Server
                    this.contactForm
                        .delete("/producers/" + this.$route.params.id + '/contacts/' + id)
                        .then(() => {
                            this.$swal({
                                icon: "success",
                                title: "Kontakt wurde gelöscht!",
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 2000,
                                timerProgressBar: true,
                                });
                            this.$parent.loadProducer();
                        })
                        .catch(() => {
                            this.$swal({
                                icon: "error",
                                title: "Oops...",
                                text: "Da ist etwas schief gelaufen",
                            });
                        });
                }
            });
        },
    }

}
</script>

<style>

</style>